import React from 'react';
import styled from 'styled-components';

const FeaturesContainer = styled.div`
  text-align: center;
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2.2rem;
  margin-bottom: 1rem;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const FeaturesGrid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 2rem;
`;

const FeatureItem = styled.div`
  width: 280px;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  text-align: center;
  transition: transform 0.3s ease-in-out; /* Smooth transition for scaling */

  &:hover {
    transform: scale(1.05); /* Slightly enlarge the box on hover */
  }
`;

const FeatureIcon = styled.div`
  margin-bottom: 1rem;
  width: 60px;
  height: 60px;
  background-color: #ae9af7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 10px;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: #666;
`;

function ClockIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#ffffff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <polyline points="12 6 12 12 16 14" />
    </svg>
  );
}

function LocateIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#ffffff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="2" x2="5" y1="12" y2="12" />
      <line x1="19" x2="22" y1="12" y2="12" />
      <line x1="12" x2="12" y1="2" y2="5" />
      <line x1="12" x2="12" y1="19" y2="22" />
      <circle cx="12" cy="12" r="7" />
    </svg>
  );
}

function UsersIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#ffffff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
      <circle cx="9" cy="7" r="4" />
      <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
      <path d="M16 3.13a4 4 0 0 1 0 7.75" />
    </svg>
  );
}

const Features = () => {
  return (
    <FeaturesContainer>
      <Title>Features That Make Hangouts Easy</Title>
      <Subtitle>Streamline your social life with our powerful scheduling tools.</Subtitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureIcon>
            <ClockIcon width="50" height="50" />
          </FeatureIcon>
          <FeatureTitle>Smart Scheduling</FeatureTitle>
          <FeatureDescription>
            Our AI-powered scheduler finds the perfect time for your hangouts, every time.
          </FeatureDescription>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon>
            <LocateIcon width="50" height="50" />
          </FeatureIcon>
          <FeatureTitle>Venue Suggestions</FeatureTitle>
          <FeatureDescription>
            Get personalized venue recommendations tailored to your group's preferences.
          </FeatureDescription>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon>
            <UsersIcon width="50" height="50" />
          </FeatureIcon>
          <FeatureTitle>Group Management</FeatureTitle>
          <FeatureDescription>
            Easily coordinate with your friends and family to plan the perfect hangout.
          </FeatureDescription>
        </FeatureItem>
      </FeaturesGrid>
    </FeaturesContainer>
  );
}

export default Features;
