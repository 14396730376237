import React from 'react';
import styled from 'styled-components';
import { FaMailBulk, FaLinkedin } from 'react-icons/fa';
import Plannr from '../assets/Titleplannr.png';
import x from '../assets/twitter.png';

const FooterContainer = styled.footer`
  background: #ffffff;
  color: #000000;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-top: 1px solid #ccc;
  text-align: center; /* Center text in mobile view */
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FooterSection = styled.div`
  flex: 1;
  margin: 10px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    flex: 100%;
    text-align: center;
  }
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  background-image: url(${Plannr});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100px;
  height: 30px;
  border-radius: 10px;
  padding: 10px;
`;

const Mission = styled.p`
  font-size: 14px;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h4`
  font-size: 16px;
  margin-bottom: 10px;
`;

const LinkList = styled.ul`
  list-style: none;
  padding: 0;
`;

const LinkItem = styled.li`
  margin-bottom: 8px;
  font-size: 14px;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;

  a {
    color: #000000;
    font-size: 24px;
  }

  img {
    width: 24px;
    height: 24px;
  }
`;

const Footer = () => (
  <FooterContainer>
    <FooterContent>
      <FooterSection>
        <Logo />
        <Mission>
          Creating your perfect hangout schedule <br></br>and venue suggestions, every time.
        </Mission>
        <SocialIcons>
          <a href="https://x.com/getplannrapp" target="_blank" rel="noopener noreferrer">
            <img src={x} alt="X" />
          </a>
          <a href="mailto:getplannrapp@gmail.com">
            <FaMailBulk />
          </a>
          <a href="https://www.linkedin.com/company/plannrapp" target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </a>
        </SocialIcons>
      </FooterSection>
      <FooterSection>
        <SectionTitle>Quick Links</SectionTitle>
        <LinkList>
          <LinkItem>Coming Soon!</LinkItem>  
        </LinkList>
      </FooterSection>
      {/* Hide the third section for now */}
      {false && (
        <FooterSection>
          <SectionTitle>Legal</SectionTitle>
          <LinkList>
            <LinkItem>Terms of Service</LinkItem>
            <LinkItem>Privacy Policy</LinkItem>
          </LinkList>
        </FooterSection>
      )}
    </FooterContent>
  </FooterContainer>
);

export default Footer;
