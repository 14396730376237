import React from 'react';
import styled from 'styled-components';
import demo from '../assets/demo.png';

// Styled Components
const BackgroundContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 40px 20px;
  background-color: #ffffff;
  min-height: 60vh;
  flex-direction: row;
  margin-left: 150px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: 0;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  max-width: 50%;
  padding-right: 20px;

  @media (max-width: 768px) {
    max-width: 100%;
    padding-right: 0;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 768px) {
    max-width: 350px;
  }
`;

const StyledImage = styled.img`
  width: 50%; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Adjust the radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for a better look */
  transition: transform 0.3s ease-in-out; /* Smooth transition for scaling */

  &:hover {
    transform: scale(1.1); /* Enlarge the image on hover */
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Heading = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Paragraph = styled.p`
  font-size: 18px;
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Highlight = styled.span`
  font-weight: bold;
  color: #7558cb;
`;

const SolutionContainer = styled.div`
  background-color: #ffffff;
  padding: 0px 20px 40px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: 0;
  }
`;

const SolutionTextContainer = styled.div`
  flex: 1;
  max-width: 50%;
  padding-right: 20px;

  @media (max-width: 768px) {
    max-width: 100%;
    padding-right: 0;
    text-align: center;
  }
`;

const SolutionHeading = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const SolutionParagraph = styled.p`
  font-size: 18px;
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Background = () => (
  <>
    <BackgroundContainer>
      <TextContainer>
        <Heading>Why Scheduling Hangouts is Hard</Heading>
        <Paragraph>
          <Highlight>Finding a mutual time</Highlight> to hang out with friends becomes increasingly challenging. Busy work schedules, family commitments, and conflicting preferences on venues and activities often lead to <Highlight>missed opportunities</Highlight> for social interactions.
        </Paragraph>
        <Paragraph>
          Even when plans are made, the difficulty of <Highlight>coordinating</Highlight> a time that works for everyone can result in skipped or missed opportunities, further complicating efforts to maintain <Highlight>meaningful connections</Highlight>.
        </Paragraph>
      </TextContainer>
      <ImageContainer>
        <StyledImage src={demo} alt="Demo" />
      </ImageContainer>
    </BackgroundContainer>
  </>
);

export default Background;
