import React from 'react';
import styled from 'styled-components';

// Styled Components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 1000; /* Ensure this is higher than other elements */
`;

const ModalContainer = styled.div`
  position: relative;
  background: white;
  padding: 30px;
  border-radius: 15px;
  width: 70%;
  max-width: 500px;
  max-height: 70%; /* Limit the height */
  overflow-y: auto; /* Enable scrolling */
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.2s ease-out;
  z-index: 1001;

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 28px;
  color: #888;
  transition: color 0.1s;

  &:hover {
    color: #333;
  }
`;

const Heading = styled.h2`
  margin-top: 0;
`;

const Text = styled.p`
  font-size: 16px;
  color: gray;
  line-height: 1.5;
`;

const WaitlistTerms = ({ onClose }) => {
  return (
    <ModalOverlay>
      <ModalContainer>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <Heading>Plannr Waitlist Terms of Service</Heading>
        <Text>Effective Date: 29th July 2024</Text>
        <Text>
          Welcome to Plannr! These Terms of Service ("Terms") govern your access to and use of the Plannr waitlist. By joining our waitlist, you agree to comply with and be bound by these Terms. Please read them carefully.
        </Text>
        <Text>
          <strong>1. Acceptance of Terms</strong>
          <br />
          By submitting your information to the Plannr waitlist, you accept and agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, please do not join the waitlist.
        </Text>
        <Text>
          <strong>2. Waitlist Registration</strong>
          <br />
          To join the Plannr waitlist, you must provide your first name and email address. You agree to provide accurate and complete information and to update your information as necessary.
        </Text>
        <Text>
          <strong>3. Use of Information</strong>
          <br />
          By joining the waitlist, you consent to Plannr using your information for the following purposes:
          <ul>
            <li>Adding you to our waitlist and keeping you informed about Plannr's launch and updates.</li>
            <li>Contacting you with information about Plannr, including promotional offers and updates.</li>
            <li>Responding to any inquiries you may have regarding our service.</li>
          </ul>
        </Text>
        <Text>
          <strong>4. Data Protection</strong>
          <br />
          We are committed to protecting your personal information. Please refer to our Privacy Policy for details on how we collect, use, and protect your data.
        </Text>
        <Text>
          <strong>5. Early Access Disclaimer</strong>
          <br />
          As an early access user, you may encounter bugs or issues while using Plannr. We are continuously working to improve our app, and your feedback is valuable to us. However, Plannr is not liable for any issues, damages, or losses that may arise from using the app during this early access phase.
        </Text>
        <Text>
          <strong>6. Changes to Terms</strong>
          <br />
          We may update these Terms from time to time. Any changes will be posted on this page, and the effective date will be updated accordingly. Your continued use of the waitlist constitutes your acceptance of the updated Terms.
        </Text>
        <Text>
          <strong>7. Termination</strong>
          <br />
          We reserve the right to remove you from the waitlist at our discretion, without prior notice, if we believe you have violated these Terms.
        </Text>
        <Text>
          <strong>8. Contact Us</strong>
          <br />
          If you have any questions or concerns about these Terms, please contact us at:
          <br />
          Email: getplannrapp@gmail.com
        </Text>
        <Text>Thank you for joining the Plannr waitlist. We look forward to keeping you informed about our exciting new app!</Text>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default WaitlistTerms;
