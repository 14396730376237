import React from 'react';
import styled from 'styled-components';
import { FaChevronDown } from 'react-icons/fa';

const FAQContainer = styled.div`
  padding: 20px;
  background: #ffffff;
  text-align: center;
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 10px;

  @media (min-width: 768px) {
    padding: 30px 50px;
  }
`;

const FAQTitle = styled.h1`
  font-size: 28px;
  color: #333;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 36px;
  }
`;

const FAQSubtitle = styled.p`
  font-size: 16px;
  color: #777;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

const FAQSubTitle = styled.p`
  font-size: 30px;
  color: #333;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 36px;
  }
`;

const FAQItem = styled.div`
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 15px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
  text-align: left;
  position: relative;

  &:hover {
    background: #f1f1f1;
  }

  h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    margin: 0;
    font-size: 16px;
    color: #555;
    display: none;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
  }

  &.active p {
    display: block;
    margin-top: 10px;
    max-height: 200px;
    opacity: 1;
  }
`;

const ChevronIcon = styled(FaChevronDown)`
  transition: transform 0.3s ease-out;
  transform: rotate(${props => (props.active ? '180deg' : '0deg')});
`;

const ContactUs = styled.div`
  margin-top: 20px;
  font-size: 16px;
  color: #777;

  & a {
    display: inline-block;
    margin-top: 10px;
    background: #8860ff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background 0.3s;

    &:hover {
      background: #6649bd;
    }
  }
`;

class FAQ extends React.Component {
  state = {
    activeIndex: null,
  };

  handleToggle = (index) => {
    this.setState({ activeIndex: this.state.activeIndex === index ? null : index });
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <FAQContainer>
        <FAQTitle>FAQs</FAQTitle>
        <FAQSubtitle>
          Got questions? We've got answers!
        </FAQSubtitle>
        {[
          { question: "Can I change the schedule once the AI decided?", answer: "Yes, just let our AI know and we'll work together to find a new schedule that suits everyone. Our AI will take care of coordinating the new timing, so everyone can all stay on the same page." },
          { question: "What happens when I reach my free plan's limit?", answer: "To accommodate a larger friend group, you will need to upgrade your plan to continue using the service. " },
          { question: "Will there be a student discount?", answer: "Yes, however there will not be an automated way to apply for a discount. Please email us with your educational institution's details and we'll provide a discount code when we launch." },
          { question: "What kind of integrations will be available?", answer: "We plan to offer integrations with a variety of apps and services, including Google Calendar, Outlook, and Apple Calendar." },
        ].map((faq, index) => (
          <FAQItem
            key={index}
            className={activeIndex === index ? 'active' : ''}
            onClick={() => this.handleToggle(index)}
            aria-expanded={activeIndex === index}
            aria-controls={`faq-content-${index}`}
            id={`faq-header-${index}`}
          >
            <h3>
              {faq.question}
              <ChevronIcon active={activeIndex === index} />
            </h3>
            <p id={`faq-content-${index}`}>{faq.answer}</p>
          </FAQItem>
        ))}
        <FAQSubTitle>
            Got More Questions?
        </FAQSubTitle>
        <ContactUs>
          Get in touch with us, we're here to help.
          <br />
          <a href="mailto:getplannrapp@gmail.com">Contact Us</a>
        </ContactUs>
      </FAQContainer>
    );
  }
}

export default FAQ;
