import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Plannr from '../assets/Titleplannr.png';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  background-image: url(${Plannr});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100px;
  height: 30px;
  border-radius: 10px;
  padding: 10px;
`;

const RightNav = styled.div`
  display: flex;
  gap: 20px;
`;

const HomeButton = styled.button`
  background-color: #ffffff;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    color: #6649bd;
  }
`;

const LoginButton = styled.button`
  background-color: #ffffff;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  display: ${({ visible }) => (visible ? 'block' : 'none')};

  &:hover {
    color: #6649bd;
  }
`;

const SignUpButton = styled.button`
  background-color: #8860ff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  display: ${({ visible }) => (visible ? 'block' : 'none')};

  &:hover {
    background: #6649bd;
  }
`;

const Header = () => {
  const [buttonsVisible, setButtonsVisible] = useState(false); // Initially set to false
  const navigate = useNavigate(); // Hook for navigation

  const handleHomeClick = () => {
    navigate('/');
  };

  return (
    <HeaderContainer>
      <Logo />
      <RightNav>
        <HomeButton onClick={handleHomeClick}>Home</HomeButton>
        <LoginButton visible={buttonsVisible}>Login</LoginButton>
        <SignUpButton visible={buttonsVisible}>Sign Up</SignUpButton>
      </RightNav>
    </HeaderContainer>
  );
};

export default Header;
