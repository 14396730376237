// src/components/Pricing.js
import React from 'react';
import styled from 'styled-components';
import Slider from "react-slick";

const PricingContainer = styled.div`
  text-align: center;
  padding: 20px 5%;
  background: #ffffff;
`;

const PricingCard = styled.div`
  background: ${props => props.bgColor || 'white'};
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
  max-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 450px;
  margin: 0 auto;

  &:hover {
    transform: scale(1.00);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  h2, p, ul li {
    transition: font-size 0.3s, color 0.3s;
  }

  h2:hover {
    font-size: 26px;
    color: #000;
  }

  p:hover {
    font-size: 18px;
    color: #555;
  }

  ul li:hover {
    font-size: 16px;
    color: #333;
  }

  h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
  }

  p {
    color: #777;
    margin-bottom: 20px;
    font-size: 16px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    text-align: left;
    flex-grow: 1;
  }

  ul li {
    margin-bottom: 10px;
    font-size: 14px;
    color: #555;
    position: relative;
    padding-left: 20px;
  }

  ul li:before {
    content: '✔';
    color: #007bff;
    position: absolute;
    left: 0;
    top: 2px;
  }

  button {
    background: #007bff;
    color: white;
    border: none;
    width: 50%;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
    transition: background 0.3s;
    margin-left: auto;
    margin-right: auto;
    display: block;

    &:hover {
      background: #0056b3;
    }
  }
`;

const Price = styled.div`
  font-size: 32px;
  color: #333;

  span {
    font-size: 16px;
    color: #777;
  }
`;

const HostNote = styled.div`
  font-size: 25px;
  color: #333;
  margin: 20px 0;

  strong {
    color: #8860ff;
  }
`;

export default function Pricing() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <PricingContainer>
      <h1>Choosing the Right Plan</h1>
      <HostNote>Only the <strong>host</strong> pays for the subscription. <br /> <strong>Guests</strong> join for free!</HostNote>
      <Slider {...settings}>
        <div>
          <PricingCard bgColor="#FFEBEE">
            <h2>Free Tier</h2>
            <Price>$0.00<span>/mo</span></Price>
            <ul>
              <li><strong>Basic features</strong> for small groups (up to 5 friends).</li>
              <li><strong>Limited number of hangout events</strong> per month (up to 3 events).</li>
              <li>Host events a maximum of <strong>7 days in advance</strong>.</li>
            </ul>
            <button style={{ display: 'none' }}>Select Plan</button>
          </PricingCard>
        </div>
        <div>
          <PricingCard bgColor="#E8F5E9">
            <h2>Premium Host Tier</h2>
            <Price>$4.99<span>/mo</span></Price>
            <ul>
              <li><strong>Bigger </strong> group size (up to 50 friends)</li>
              <li><strong>Unlimited </strong>hangout events</li>
              <li>Host events with <strong>no advance time restrictions</strong></li>
              <li><strong>Priority AI processing</strong> for faster scheduling</li>
              <li>Integration with <strong>calendar apps</strong></li>
              <li>Customizable <strong>notifications and reminders</strong></li>
              <li>Dedicated <strong>customer support</strong></li>
              <li><strong>No Ads</strong></li>
            </ul>
            <button style={{ display: 'none' }}>Select Plan</button>
          </PricingCard>
        </div>
        <div>
          <PricingCard bgColor="#E3F2FD">
            <h2>Business Host Tier</h2>
            <Price>$9.99<span>/mo</span></Price>
            <ul>
              <li>All <strong>Premium Host features</strong></li>
              <li><strong>Bigger </strong> group size (up to 150 co-workers)</li>
              <li>Additional tools for <strong>team-building</strong> and corporate events</li>
              <li><strong>Analytics dashboard</strong> for event participation and engagement</li>
              <li>Dedicated <strong>customer support</strong></li>
              <li><strong>No Ads</strong></li>
            </ul>
            <button style={{ display: 'none' }}>Select Plan</button>
          </PricingCard>
        </div>
      </Slider>
    </PricingContainer>
  );
}
