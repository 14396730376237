import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Footer from './components/Footer';
import Pricing from './components/Pricing';
import Background from './components/Background';
import FAQ from './components/FAQ';
import Features from './components/Features';
import PricingMobile from './components/PricingMobile';
import useMediaQuery from './hooks/useMediaQuery'; // Adjust the path as necessary
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const App = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={
          <>
            <Home />
            <Background />
            <Features />
            {isMobile ? <PricingMobile /> : <Pricing />}
            <FAQ />
          </>
        } />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
