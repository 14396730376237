import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { openDB } from 'idb';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'; // Import checkmark and error icons
import demo from '../assets/demo.mp4';
import WaitlistPrivacy from './WaitlistPrivacy';
import WaitlistTerms from './WaitlistTerms'; // Import WaitlistTerms

// Styled Components
const HomeContainer = styled.div`
  text-align: center;
  padding: 0 10px; /* Reduced padding for mobile */
`;

const Heading = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
  font-weight: bold;

  @media (min-width: 768px) {
    font-size: 48px;
  }
`;

const SubHeading = styled.p`
  font-size: 18px;
  color: gray;
  margin-bottom: 20px;
  line-height: 1.5;

  @media (min-width: 768px) {
    font-size: 24px;
    margin-bottom: 40px;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #8860ff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #6649bd;
  }

  @media (min-width: 768px) {
    padding: 12px 24px;
    font-size: 18px;
  }
`;

const VideoContainer = styled.div`
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-top: 40px;
  }
`;

const DemoVideo = styled.video`
  width: 100%;
  max-width: 600px;
  border-radius: 20px;
  background-color: black;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 25px;
  }
`;

// Modal Styles
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 1000;
`;

const ModalContainer = styled.div`
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 15px;
  width: 80%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.2s ease-out;
  z-index: 1001;

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @media (min-width: 768px) {
    padding: 30px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #888;
  transition: color 0.3s;

  &:hover {
    color: #333;
  }

  @media (min-width: 768px) {
    font-size: 28px;
  }
`;

const Input = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;

  @media (min-width: 768px) {
    padding: 12px;
    font-size: 16px;
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: ${({ isLoading }) => (isLoading ? '#aaa' : '#8860ff')};
  border: none;
  border-radius: 5px;
  cursor: ${({ isLoading }) => (isLoading ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ isLoading }) => (isLoading ? '#aaa' : '#6649bd')};
  }

  @media (min-width: 768px) {
    padding: 12px 24px;
    font-size: 18px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  color: gray;
  margin-top: 10px;
  line-height: 1.5;

  @media (min-width: 768px) {
    font-size: 18px;
    margin-top: 20px;
  }
`;

const PrivacyLink = styled.span`
  color: #8860ff;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px;
`;

const TermsLink = styled.span`
  color: #8860ff;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

const SuccessMessage = styled(MessageContainer)`
  color: #28a745; /* Green color for success */
`;

const ErrorMessage = styled(MessageContainer)`
  color: #dc3545; /* Red color for error */
`;

const CheckIcon = styled(FaCheckCircle)`
  font-size: 30px;
  margin-top: 20px;

  @media (min-width: 768px) {
    font-size: 40px;
    margin-top: 25px;
  }
`;

const ErrorIcon = styled(FaTimesCircle)`
  font-size: 30px;
  margin-top: 20px;

  @media (min-width: 768px) {
    font-size: 40px;
    margin-top: 25px;
  }
`;

const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

// Main Component
const Home = () => {
  const videoRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false); // Add state for Terms modal
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submissionError, setSubmissionError] = useState(false); // Add state for submission error
  const [videoSrc, setVideoSrc] = useState(demo); // State for video source
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State for loading

  useEffect(() => {
    const fetchAndCacheVideo = async () => {
      const db = await openDB('demoVideoDB', 1, {
        upgrade(db) {
          db.createObjectStore('videos');
        }
      });

      const cachedVideo = await db.get('videos', 'demoVideoCache');

      if (cachedVideo) {
        setVideoSrc(cachedVideo);
        //console.log("Cached")
      } else {
        const response = await fetch(demo);
        const blob = await response.blob();
        const base64 = await blobToBase64(blob);

        await db.put('videos', base64, 'demoVideoCache');
        setVideoSrc(base64);
        //console.log("Setup Cache")
      }
    };

    fetchAndCacheVideo();

    if (videoRef.current) {
      videoRef.current.playbackRate = 1.0;
    }
  }, []);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (!isSubmitted) {
      setSubmissionError(false); // Reset error state when closing modal if not submitted
    }
  };

  const handlePrivacyClick = () => {
    setIsPrivacyOpen(true);
  };

  const handleClosePrivacy = () => {
    setIsPrivacyOpen(false);
  };

  const handleTermsClick = () => {
    setIsTermsOpen(true); // Open Terms modal
  };

  const handleCloseTerms = () => {
    setIsTermsOpen(false); // Close Terms modal
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch('/api/waitlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ firstName, email })
      });

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const result = await response.json();
        if (response.ok) {
          setIsSubmitted(true);
          setSubmissionError(false);
          console.log(result.message);
        } else {
          setSubmissionError(true);
          console.error(result.error);
        }
      } else {
        setSubmissionError(true);
      }
    } catch (error) {
      setSubmissionError(true);
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <HomeContainer>
      <Heading>
        The <span style={{ color: '#8860ff' }}>No-Stress</span> Hangouts Scheduler.<br />
        Perfect Overlaps, Every Time✨
      </Heading>
      <SubHeading>Never miss a hangout again. Get AI-coordinated schedules, perfect<br /> venue suggestions, and plan the best get-togethers.</SubHeading>
      <VideoContainer>
        <DemoVideo ref={videoRef} autoPlay muted loop>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </DemoVideo>
      </VideoContainer>
      <Button onClick={handleButtonClick}>Join Waitlist</Button>
      {isModalOpen && (
        <ModalOverlay>
          <ModalContainer>
            <CloseButton onClick={handleCloseModal}>&times;</CloseButton>
            {!isSubmitted && !submissionError ? (
              <>
                <h2>Join the Waitlist</h2>
                <Description>Be one of the first to experience Plannr <br></br>during our early access phase!</Description>
                <form onSubmit={handleSubmit}>
                  <Input
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                  <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <SubmitButton type="submit" isLoading={isLoading} disabled={isLoading}>
                    {isLoading ? 'Submitting...' : 'Submit'}
                  </SubmitButton>
                  <Description>
                    By submitting you agree to our waitlist
                    <TermsLink onClick={handleTermsClick}>Terms of Service</TermsLink> and
                    <PrivacyLink onClick={handlePrivacyClick}>Privacy Policy</PrivacyLink>.
                  </Description>
                </form>
              </>
            ) : isSubmitted ? (
              <SuccessMessage>
                <CheckIcon />
                <h2>You're in!</h2>
                <Description>Thank you for joining the waitlist. We'll keep you updated!</Description>
              </SuccessMessage>
            ) : (
              <ErrorMessage>
                <ErrorIcon />
                <h2>Submission Failed</h2>
                <Description>Something went wrong. Please try again later.<br></br>If the issue persists, contact us at getplannrapp@gmail.com.</Description>
              </ErrorMessage>
            )}
          </ModalContainer>
        </ModalOverlay>
      )}
      {isPrivacyOpen && <WaitlistPrivacy onClose={handleClosePrivacy} />}
      {isTermsOpen && <WaitlistTerms onClose={handleCloseTerms} />} {/* Add Terms modal */}
    </HomeContainer>
  );
};

export default Home;
